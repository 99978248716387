<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            v-if="$ability.can('C', 'Activity & Sub Activity')"
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                :to="{ name: 'general-settings-activities-create' }"
              >
                <span class="text-nowrap">Add Activity</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refActivityListTable"
        class="position-relative"
        :items="fetchActivities"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Show Details -->
        <template #cell(show_details)="row">
          <b-button
            v-ripple.400="'rgba(53, 197, 240, 0.15))'"
            variant="flat-info"
            class="btn-icon rounded-circle"
            size="sm"
            @click="row.toggleDetails"
          >
            <feather-icon :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
          </b-button>
        </template>

        <!-- Column: Row Details -->
        <template #row-details="row">

          <!-- Sub Activities Table -->
          <sub-activities-list
            :subactivities="row.item.subactivities"
            @refetch-data="refetchData"
          />

        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="$ability.can('E', 'Activity & Sub Activity')"
              :to="{ name: 'general-settings-activities-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$ability.can('D', 'Activity & Sub Activity')"
              @click="deleteActivity({ activityId: data.item.id })"
            >
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import useActivitiesList from './useActivitiesList'
import activityStoreModule from '../activityStoreModule'
import SubActivitiesList from './subactivities-list/SubActivitiesList.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    SubActivitiesList,
  },
  directives: {
    Ripple,
  },
  setup() {
    const ACTIVITY_APP_STORE_MODULE_NAME = 'app-activity'

    // Register module
    if (!store.hasModule(ACTIVITY_APP_STORE_MODULE_NAME)) store.registerModule(ACTIVITY_APP_STORE_MODULE_NAME, activityStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACTIVITY_APP_STORE_MODULE_NAME)) store.unregisterModule(ACTIVITY_APP_STORE_MODULE_NAME)
    })

    const {
      fetchActivities,
      tableColumns,
      perPage,
      currentPage,
      totalRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refActivityListTable,
      refetchData,

      deleteActivity,
    } = useActivitiesList()

    return {
      fetchActivities,
      tableColumns,
      perPage,
      currentPage,
      totalRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refActivityListTable,
      refetchData,

      deleteActivity,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
