import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default function useActivitiesList() {
  // Use toast
  const toast = useToast()

  const refActivityListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'actions', sortable: false },
    { key: 'name', sortable: false },
    {
      key: 'show_details',
      sortable: false,
      label: '',
      class: 'text-right',
    },
  ]
  const perPage = ref(10)
  const totalRecords = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refActivityListTable.value ? refActivityListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRecords.value,
    }
  })

  const refetchData = () => {
    refActivityListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchActivities = (ctx, callback) => {
    store
      .dispatch('app-activity/fetchActivities', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const records = response.data.data

        callback(records)
        totalRecords.value = records.length
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Activity list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteActivity = deleteParams => {
    store.dispatch('app-activity/deleteActivity', deleteParams)
    refetchData()
  }

  //   Resolve Activity
  const activityOptions = computed(() => store.getters['app-activity/activityOptions'])

  const resolveActivity = activityId => {
    const activity = activityOptions.value.find(obj => (obj.value === activityId))
    return activity ? activity.label : activityId
  }

  return {
    fetchActivities,
    tableColumns,
    perPage,
    currentPage,
    totalRecords,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refActivityListTable,
    refetchData,

    deleteActivity,

    //  UI
    resolveActivity,
  }
}
