export default function useSubActivitiesList() {
  // Table Handlers
  const tableColumns = [
    { key: 'actions', sortable: false },
    { key: 'name', sortable: true },
  ]

  return {
    tableColumns,
  }
}
