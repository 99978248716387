<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="border-light"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Add -->
          <b-col
            v-if="$ability.can('C', 'Activity & Sub Activity')"
            cols="12"
            md="12"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                :to="{ name: 'general-settings-activities-create' }"
              >
                <span class="text-nowrap">Add Sub Activity</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refSubActivityListTable"
        class="position-relative"
        :items="subactivities"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="$ability.can('E', 'Activity & Sub Activity')"
              :to="{ name: 'general-settings-subactivities-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$ability.can('D', 'Activity & Sub Activity')"
              @click="deleteSubActivity({ subactivityId: data.item.id })"
            >
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useSubActivitiesList from './useSubActivitiesList'
import store from '@/store'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  props: {
    subactivities: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { tableColumns } = useSubActivitiesList()

    const deleteSubActivity = deleteParams => {
      store.dispatch('app-activity/deleteSubActivity', deleteParams)
      emit('refetch-data')
    }

    return {
      tableColumns,

      deleteSubActivity,
    }
  },
}
</script>
